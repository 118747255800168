@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Smooch&display=swap');
@import url('https://app.meetfox.com/assets/styles/popup.css');

body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
