/* CUSTOM COLORS */
body{
	margin:0;
	font-family: "Josefin Sans", sans-serif;
}
:root {
	--renove:#8F9F6D;
	--light:#BCBB6F;
	--brown:#857A64;
}
#logo{
	max-height:100px;
	margin:10px;
}
.content{
	width: 100%;
	max-width:1320px;
	margin:0 auto;
}
.lead{
	font-size:25px!important;
}
.bg-renove{
	background-color:var(--renove);
	color:white;
	padding:5px 10px;
	width:100%;
}
.bg-renove span{
	font-size:20px;
}
.navbar{
	padding-top:0;
}

.welcome{
	font-family: "Smooch", cursive;
	font-size:50px;
	color:var(--renove);
}
.renove-head{
	border-bottom:solid 1px var(--renove);
	position:relative;
}
.renove-top-menu{
	float:right;
	position:absolute;
	right:10px;
	bottom:5px;
}
.home-titles{
	font-size:40px;
    font-weight:300;
	text-align:center;
}
a.home-thumb{
	text-decoration: none;
}
.home-thumb .card:hover{
	cursor:pointer;
	background-color: lavender;
    box-shadow: none!important;
}
#home-photo{
	width:100%;
	height:400px;
	background-image:url("./images/home-photo.jpg");
	background-repeat:no-repeat;
	background-size:cover;
	background-position:bottom;
}
.page-featured-image{
	width:100%;
	height:300px;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center;
	margin-bottom:20px;
}
.btn-appointment{
	cursor: pointer; 
	font-family: Poppins; 
	font-weight: 700; 
	background-color: #8f9f6d; 
	color: white; 
	padding: 0.8rem 2rem; 
	border: 0px; 
	box-shadow: rgba(0, 0, 0, 0.15) 0px -2px 0px inset; 
	border-radius: 4px; 
	text-decoration: none; 
	display: inline-block;
}
.btn-top{
	float:right;
	margin:10px 0 0 0;
}
.hidden{ display:none; }
#bannerServicesConsultation{ background-image:url('./images/initial-consultation.jpg'); }
#bannerServicesIntegrativeWellnessAndLifestyle{ background-image:url('images/initial-consultation.jpg'); }
#bannerServicesExerciseCounseling{ background-image:url('images/services-exercise-counseling.jpg'); }
#bannerServicesFunctionalNutrition{ background-image:url('images/services-functional-nutrition-and-supplements.jpg'); }
#bannerServicesNonInvasiveSkinBodyTreatment{ background-image:url('images/services-non-invasive-skin-and-body-treatments.jpg'); }
#bannerSpecialtyHealthWellness{ background-image:url('images/health-and-wellness.jpg'); }
#bannerSpecialtyWomansHealth{ background-image:url('images/womens-health.jpg'); }
#bannerSpecialtyChronicConditions{ background-image:url('images/chronic-conditions.jpg'); }
#bannerSpecialtyWeightManagement{ background-image:url('images/weight-management.jpg'); }
#bannerSpecialtyBeautyAging{ background-image:url('images/beauty-and-healthy-aging.jpg'); }
#bannerSpecialtyChildrenTeens{ background-image:url('images/children-and-teens-health.jpg'); }

#btnBookAppointment{
	cursor: pointer; 
	font-family: Poppins; 
	font-weight: 700; 
	background-color: #8f9f6d; 
	color: white; 
	padding: 0.8rem 2rem; 
	border: 0px; 
	box-shadow: rgba(0, 0, 0, 0.15) 0px -2px 0px inset; 
	border-radius: 4px; 
	text-decoration: none; 
	display: inline-block;
}
@media screen and (min-width: 1300px){
	.content{
		max-width: 1200px;
	}
}
@media screen and (max-width: 600px){
	.btn-appointment{
		padding: 0.5rem 1rem;
        font-size: 12px; 
	}
	.btn-top{ margin:10px 10px 0 0; }
	#logo { max-height: 70px; margin:7px 0 0 15px; }
	.renove-head{ height:110px; }
	.renove-top-menu{
		float: none;
        position: relative;
        margin: 0 10px;
        right: auto;
        bottom: auto;
	}
	.navbar-expand .navbar-nav{ justify-content:space-around; }
	.dropdown-menu.show{ max-width:300px; overflow:hidden; }
}